import React, { FC, useEffect, useState } from "react";
import "./Sidebar.css";
import FollowUs from "../FollowUs/FollowUs";
import NewsLetter from "../NewsLetter/NewsLetter";
import { ArticleContentType } from "../../interfaces";
import axios from "axios";
import parse from "html-react-parser";

interface SidebarProps {
  isLoading: boolean;
}

const Sidebar: FC<SidebarProps> = ({ isLoading }) => {
  const [articleContentTypes, setArticleContentTypes] =
    useState<ArticleContentType[]>();
  const [popularContents, setPopularContents] = useState<ArticleContentType>();
  const [trendingContents, setTrendingContents] =
    useState<ArticleContentType>();
  const [latestContents, setLatestContents] = useState<ArticleContentType>();
  const url = `https://apis.nuhman.com/`;

  useEffect(() => {
    axios
      .get(
        url + `apis/education/article-content-type?articleCategoryId=1&max=15`
      )
      .then((res) => {
        //setCountries(res?.data);
        setArticleContentTypes(res?.data);
        const popularContentFilter = (
          articleContentType: ArticleContentType
        ): boolean => articleContentType.contentType?.name === "popular";
        let popularContent = res?.data?.find(popularContentFilter);
        setPopularContents(popularContent);
        console.log(popularContent);

        const trendingContentFilter = (
          articleContentType: ArticleContentType
        ): boolean => articleContentType.contentType?.name === "trending";
        const trendingContent = res?.data?.find(trendingContentFilter);
        setTrendingContents(trendingContent);

        const latestContentFilter = (
          articleContentType: ArticleContentType
        ): boolean => articleContentType.contentType?.name === "latest";
        const latestContent = res?.data?.find(latestContentFilter);
        setLatestContents(latestContent);
      })
      .catch((err) => console.log(err));
  }, [isLoading]);

  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="row mb-3">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    <h3 className="m-0">Popular</h3>
                    <a
                      className="text-secondary font-weight-medium text-decoration-none"
                      href=""
                    >
                      View All
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="position-relative mb-3">
                    <img
                      className="img-fluid w-100 imgcover"
                      src={
                        "https://collegesearchers.com/images/" +
                          (popularContents?.articles &&
                            popularContents?.articles[0].image500x280) || "test"
                      }
                    />
                    <div className="overlay position-relative bg-light">
                      <div className="mb-2  headerforteen">
                        <a
                          href={
                            "/#/categories?catid=" +
                            (popularContents?.articles &&
                              popularContents?.articles[0].educationCategory
                                ?.id)
                          }
                        >
                          {popularContents?.articles &&
                            popularContents?.articles[0].educationCategory
                              ?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {popularContents?.articles &&
                            new Date(
                              popularContents?.articles[0]?.createdDateTime ||
                                ""
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h4"
                        href={
                          "/#/article?articleid=" +
                          (popularContents?.articles &&
                            popularContents?.articles[0].id)
                        }
                      >
                        {popularContents?.articles &&
                          popularContents?.articles[0].title}
                      </a>
                      <p className="m-0">
                        {popularContents?.articles &&
                          parse(
                            popularContents?.articles[0].shortDescription || ""
                          )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <img
                      src={
                        "https://collegesearchers.com/images/" +
                          (popularContents?.articles &&
                            popularContents?.articles[3].image100x100) || "test"
                      }
                      className="imgwhcover"
                    />
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?catid=" +
                            (popularContents?.articles &&
                              popularContents?.articles[3].educationCategory
                                ?.id)
                          }
                        >
                          {popularContents?.articles &&
                            popularContents?.articles[3].educationCategory
                              ?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {popularContents?.articles &&
                            new Date(
                              popularContents?.articles[3]?.createdDateTime ||
                                ""
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (popularContents?.articles &&
                            popularContents?.articles[3].id)
                        }
                      >
                        {popularContents?.articles &&
                          popularContents?.articles[3].title}
                      </a>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <img
                      src={
                        "https://collegesearchers.com/images/" +
                          (popularContents?.articles &&
                            popularContents?.articles[4].image100x100) || "test"
                      }
                      className="imgwhcover"
                    />
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?catid=" +
                            (popularContents?.articles &&
                              popularContents?.articles[4].educationCategory
                                ?.id)
                          }
                        >
                          {popularContents?.articles &&
                            popularContents?.articles[4].educationCategory
                              ?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {popularContents?.articles &&
                            new Date(
                              popularContents?.articles[4]?.createdDateTime ||
                                ""
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (popularContents?.articles &&
                            popularContents?.articles[4].id)
                        }
                      >
                        {popularContents?.articles &&
                          popularContents?.articles[4].title}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="position-relative mb-3">
                    <img
                      className="img-fluid w-100 imgcover"
                      src={
                        "https://collegesearchers.com/images/" +
                          (popularContents?.articles &&
                            popularContents?.articles[1].image500x280) || "test"
                      }
                    />
                    <div className="overlay position-relative bg-light">
                      <div className="mb-2  headerforteen">
                        <a
                          href={
                            "/#/categories?catid=" +
                            (popularContents?.articles &&
                              popularContents?.articles[1].educationCategory
                                ?.id)
                          }
                        >
                          {popularContents?.articles &&
                            popularContents?.articles[1].educationCategory
                              ?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {popularContents?.articles &&
                            new Date(
                              popularContents?.articles[1]?.createdDateTime ||
                                ""
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h4"
                        href={
                          "/#/article?articleid=" +
                          (popularContents?.articles &&
                            popularContents?.articles[1].id)
                        }
                      >
                        {popularContents?.articles &&
                          popularContents?.articles[1].title}
                      </a>
                      <p className="m-0">
                        {popularContents?.articles &&
                          parse(
                            popularContents?.articles[1].shortDescription || ""
                          )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <img
                      src={
                        "https://collegesearchers.com/images/" +
                          (popularContents?.articles &&
                            popularContents?.articles[2].image100x100) || "test"
                      }
                      className="imgwhcover"
                    />
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?catid=" +
                            (popularContents?.articles &&
                              popularContents?.articles[2].educationCategory
                                ?.id)
                          }
                        >
                          {popularContents?.articles &&
                            popularContents?.articles[2].educationCategory
                              ?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {popularContents?.articles &&
                            new Date(
                              popularContents?.articles[2]?.createdDateTime ||
                                ""
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (popularContents?.articles &&
                            popularContents?.articles[2].id)
                        }
                      >
                        {popularContents?.articles &&
                          popularContents?.articles[2].title}
                      </a>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <img
                      src={
                        "https://collegesearchers.com/images/" +
                          (popularContents?.articles &&
                            popularContents?.articles[5].image100x100) || "test"
                      }
                      className="imgwhcover"
                    />
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?catid=" +
                            (popularContents?.articles &&
                              popularContents?.articles[5].educationCategory
                                ?.id)
                          }
                        >
                          {popularContents?.articles &&
                            popularContents?.articles[5].educationCategory
                              ?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {popularContents?.articles &&
                            new Date(
                              popularContents?.articles[5]?.createdDateTime ||
                                ""
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (popularContents?.articles &&
                            popularContents?.articles[5].id)
                        }
                      >
                        {popularContents?.articles &&
                          popularContents?.articles[5].title}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    <h3 className="m-0">Latest</h3>
                    <a
                      className="text-secondary font-weight-medium text-decoration-none"
                      href=""
                    >
                      View All
                    </a>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="position-relative mb-3">
                    <img
                      className="img-fluid w-100 imgcover"
                      src={
                        "https://collegesearchers.com/images/" +
                          (latestContents?.articles &&
                            latestContents?.articles[0].image500x280) || "test"
                      }
                    />
                    <div className="overlay position-relative bg-light">
                      <div className="mb-2 headerfourteen">
                        <a
                          href={
                            "/#/categories?catid=" +
                            (latestContents?.articles &&
                              latestContents?.articles[0].educationCategory?.id)
                          }
                        >
                          {latestContents?.articles &&
                            latestContents?.articles[0].educationCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.articles &&
                            new Date(
                              latestContents?.articles[0]?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h4"
                        href={
                          "/#/article?articleid=" +
                          (latestContents?.articles &&
                            latestContents?.articles[0].id)
                        }
                      >
                        {latestContents?.articles &&
                          latestContents?.articles[0].title}
                      </a>
                      <p className="m-0">
                        {latestContents?.articles &&
                          parse(
                            latestContents?.articles[0].shortDescription || ""
                          )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <img
                      src={
                        "https://collegesearchers.com/images/" +
                          (latestContents?.articles &&
                            latestContents?.articles[2].image100x100) || "test"
                      }
                      className="imgwhcover"
                    />
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?catid=" +
                            (latestContents?.articles &&
                              latestContents?.articles[2].educationCategory?.id)
                          }
                        >
                          {latestContents?.articles &&
                            latestContents?.articles[2].educationCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.articles &&
                            new Date(
                              latestContents?.articles[2]?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (latestContents?.articles &&
                            latestContents?.articles[2].id)
                        }
                      >
                        {latestContents?.articles &&
                          latestContents?.articles[2].title}
                      </a>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <img
                      src={
                        "https://collegesearchers.com/images/" +
                          (latestContents?.articles &&
                            latestContents?.articles[5].image100x100) || "test"
                      }
                      className="imgwhcover"
                    />
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?catid=" +
                            (latestContents?.articles &&
                              latestContents?.articles[5].educationCategory?.id)
                          }
                        >
                          {latestContents?.articles &&
                            latestContents?.articles[5].educationCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.articles &&
                            new Date(
                              latestContents?.articles[5]?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (latestContents?.articles &&
                            latestContents?.articles[5].id)
                        }
                      >
                        {latestContents?.articles &&
                          latestContents?.articles[5].title?.substring(0, 66) +
                            "...."}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="position-relative mb-3">
                    <img
                      className="img-fluid w-100 imgcover"
                      src={
                        "https://collegesearchers.com/images/" +
                          (latestContents?.articles &&
                            latestContents?.articles[1].image500x280) || "test"
                      }
                    />
                    <div className="overlay position-relative bg-light">
                      <div className="mb-2 headerfourteen">
                        <a
                          href={
                            "/#/categories?catid=" +
                            (latestContents?.articles &&
                              latestContents?.articles[1].educationCategory?.id)
                          }
                        >
                          {latestContents?.articles &&
                            latestContents?.articles[1].educationCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.articles &&
                            new Date(
                              latestContents?.articles[1]?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h4"
                        href={
                          "/#/article?articleid=" +
                          (latestContents?.articles &&
                            latestContents?.articles[1].id)
                        }
                      >
                        {latestContents?.articles &&
                          latestContents?.articles[1].title}
                      </a>
                      <p className="m-0">
                        {latestContents?.articles &&
                          parse(
                            latestContents?.articles[1].shortDescription || ""
                          )}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <img
                      src={
                        "https://collegesearchers.com/images/" +
                          (latestContents?.articles &&
                            latestContents?.articles[3].image100x100) || "test"
                      }
                      className="imgwhcover"
                    />
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?catid=" +
                            (latestContents?.articles &&
                              latestContents?.articles[3].educationCategory?.id)
                          }
                        >
                          {latestContents?.articles &&
                            latestContents?.articles[3].educationCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.articles &&
                            new Date(
                              latestContents?.articles[3]?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (latestContents?.articles &&
                            latestContents?.articles[3].id)
                        }
                      >
                        {latestContents?.articles &&
                          latestContents?.articles[3].title}
                      </a>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <img
                      src={
                        "https://collegesearchers.com/images/" +
                          (latestContents?.articles &&
                            latestContents?.articles[4].image100x100) || "test"
                      }
                      className="imgwhcover"
                    />
                    <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                      <div className="mb-1 headerthirteen">
                        <a
                          href={
                            "/#/categories?catid=" +
                            (latestContents?.articles &&
                              latestContents?.articles[4].educationCategory?.id)
                          }
                        >
                          {latestContents?.articles &&
                            latestContents?.articles[4].educationCategory?.name}
                        </a>
                        <span className="px-1">/</span>
                        <span>
                          {latestContents?.articles &&
                            new Date(
                              latestContents?.articles[4]?.createdDateTime || ""
                            ).toLocaleDateString("en-US", {
                              weekday: "long",
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })}
                        </span>
                      </div>
                      <a
                        className="h6 m-0"
                        href={
                          "/#/article?articleid=" +
                          (latestContents?.articles &&
                            latestContents?.articles[4].id)
                        }
                      >
                        {latestContents?.articles &&
                          latestContents?.articles[4].title}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pt-3 pt-lg-0">
              <FollowUs />
              <NewsLetter />
              <div className="mb-3 pb-3">
                <script
                  type="text/javascript"
                  src="https://udbaa.com/bnr.php?section=General&pub=572899&format=300x250&ga=g"
                ></script>
                <noscript>
                  <a href="https://yllix.com/publishers/572899" target="_blank">
                    <img
                      src="//ylx-aff.advertica-cdn.com/pub/300x250.png"
                      className="ylixs"
                      alt="ylliX - Online Advertising Network"
                    />
                  </a>
                </noscript>
              </div>
              <div className="pb-3">
                <div className="bg-light py-2 px-4 mb-3">
                  <h3 className="m-0">Trending</h3>
                </div>
                <div className="d-flex mb-3">
                  <img src="img/news-100x100-1.jpg" className="imgwhcover" />
                  <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                    <div className="mb-1 headerthirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h6 m-0" href="">
                      Lorem ipsum dolor sit amet consec adipis elit
                    </a>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <img src="img/news-100x100-2.jpg" className="imgwhcover" />
                  <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                    <div className="mb-1 headerthirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h6 m-0" href="">
                      Lorem ipsum dolor sit amet consec adipis elit
                    </a>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <img src="img/news-100x100-3.jpg" className="imgwhcover" />
                  <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                    <div className="mb-1 headerthirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h6 m-0" href="">
                      Lorem ipsum dolor sit amet consec adipis elit
                    </a>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <img src="img/news-100x100-4.jpg" className="imgwhcover" />
                  <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                    <div className="mb-1 headerthirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h6 m-0" href="">
                      Lorem ipsum dolor sit amet consec adipis elit
                    </a>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <img src="img/news-100x100-5.jpg" className="imgwhcover" />
                  <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                    <div className="mb-1 headerthirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h6 m-0" href="">
                      Lorem ipsum dolor sit amet consec adipis elit
                    </a>
                  </div>
                </div>
              </div>

              <div className="pb-3">
                <div className="bg-light py-2 px-4 mb-3">
                  <h3 className="m-0">Tags</h3>
                </div>
                <div className="d-flex flex-wrap m-n1">
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Politics
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Business
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Corporate
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Sports
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Health
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Education
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Science
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Technology
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Foods
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Entertainment
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Travel
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Lifestyle
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
