import React, { FC, useEffect, useState } from "react";
import "./FeaturedSlider.css";
import axios from "axios";
import parse from "html-react-parser";
import { ArticleContentType } from "../../interfaces";

interface FeaturedSliderProps {}

const FeaturedSlider: FC<FeaturedSliderProps> = () => {
  const [articleContentTypes, setArticleContentTypes] =
    useState<ArticleContentType[]>();
  const [popularContents, setPopularContents] = useState<ArticleContentType>();
  const [trendingContents, setTrendingContents] =
    useState<ArticleContentType>();
  const [latestContents, setLatestContents] = useState<ArticleContentType>();
  const url = `https://apis.nuhman.com/`;

  const today = new Date();
  let text = today.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  useEffect(() => {
    axios
      .get(
        url + `apis/education/article-content-type?articleCategoryId=1&max=10`
      )
      .then((res) => {
        //setCountries(res?.data);
        setArticleContentTypes(res?.data);
        const popularContentFilter = (
          articleContentType: ArticleContentType
        ): boolean => articleContentType.contentType?.name === "featured";
        let popularContent = res?.data?.find(popularContentFilter);
        setPopularContents(popularContent);
        console.log(popularContent);

        const trendingContentFilter = (
          articleContentType: ArticleContentType
        ): boolean => articleContentType.contentType?.name === "trending";
        const trendingContent = res?.data?.find(trendingContentFilter);
        setTrendingContents(trendingContent);

        const latestContentFilter = (
          articleContentType: ArticleContentType
        ): boolean => articleContentType.contentType?.name === "latest";
        const latestContent = res?.data?.find(latestContentFilter);
        setLatestContents(latestContent);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <div className="container-fluid ">
        <div className="container">
          <div className="bg-light ">
            <h3 className="hstyle">Featured</h3>
            <div className="col-sm-6 intdisp">
              <div className="d-flex mb-3">
                <img
                  src={
                    "https://collegesearchers.com/images/" +
                      (popularContents?.articles &&
                        popularContents?.articles[0].image100x100) || "test"
                  }
                  className="imgwhcover"
                />
                <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                  <div className="mb-1 headerthirteen">
                    <a
                      href={
                        "/#/categories?catid=" +
                        (popularContents?.articles &&
                          popularContents?.articles[0].educationCategory?.id)
                      }
                    >
                      {popularContents?.articles &&
                        popularContents?.articles[0].educationCategory?.name}
                    </a>
                    <span className="px-1">/</span>
                    <span>
                      {popularContents?.articles &&
                        new Date(
                          popularContents?.articles[0]?.createdDateTime || ""
                        ).toLocaleDateString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                    </span>
                  </div>
                  <a
                    className="h6 m-0"
                    href={
                      "/#/article?articleid=" +
                      (popularContents?.articles &&
                        popularContents?.articles[0].id)
                    }
                  >
                    {popularContents?.articles &&
                      popularContents?.articles[0].title}
                  </a>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 intdisp">
              <div className="d-flex mb-3">
                <img
                  src={
                    "https://collegesearchers.com/images/" +
                      (popularContents?.articles &&
                        popularContents?.articles[1].image100x100) || "test"
                  }
                  className="imgwhcover"
                />
                <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                  <div className="mb-1 headerthirteen">
                    <a
                      href={
                        "/#/categories?catid=" +
                        (popularContents?.articles &&
                          popularContents?.articles[1].educationCategory?.id)
                      }
                    >
                      {popularContents?.articles &&
                        popularContents?.articles[1].educationCategory?.name}
                    </a>
                    <span className="px-1">/</span>
                    <span>
                      {popularContents?.articles &&
                        new Date(
                          popularContents?.articles[1]?.createdDateTime || ""
                        ).toLocaleDateString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                    </span>
                  </div>
                  <a
                    className="h6 m-0"
                    href={
                      "/#/article?articleid=" +
                      (popularContents?.articles &&
                        popularContents?.articles[1].id)
                    }
                  >
                    {popularContents?.articles &&
                      popularContents?.articles[1].title}
                  </a>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 intdisp">
              <div className="d-flex mb-3">
                <img
                  src={
                    "https://collegesearchers.com/images/" +
                      (popularContents?.articles &&
                        popularContents?.articles[2].image100x100) || "test"
                  }
                  className="imgwhcover"
                />
                <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                  <div className="mb-1 headerthirteen">
                    <a
                      href={
                        "/#/categories?catid=" +
                        (popularContents?.articles &&
                          popularContents?.articles[2].educationCategory?.id)
                      }
                    >
                      {popularContents?.articles &&
                        popularContents?.articles[2].educationCategory?.name}
                    </a>
                    <span className="px-1">/</span>
                    <span>
                      {popularContents?.articles &&
                        new Date(
                          popularContents?.articles[2]?.createdDateTime || ""
                        ).toLocaleDateString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                    </span>
                  </div>
                  <a
                    className="h6 m-0"
                    href={
                      "/#/article?articleid=" +
                      (popularContents?.articles &&
                        popularContents?.articles[2].id)
                    }
                  >
                    {popularContents?.articles &&
                      popularContents?.articles[2].title}
                  </a>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 intdisp">
              <div className="d-flex mb-3">
                <img
                  src={
                    "https://collegesearchers.com/images/" +
                      (popularContents?.articles &&
                        popularContents?.articles[3].image100x100) || "test"
                  }
                  className="imgwhcover"
                />
                <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                  <div className="mb-1 headerthirteen">
                    <a
                      href={
                        "/#/categories?catid=" +
                        (popularContents?.articles &&
                          popularContents?.articles[3].educationCategory?.id)
                      }
                    >
                      {popularContents?.articles &&
                        popularContents?.articles[3].educationCategory?.name}
                    </a>
                    <span className="px-1">/</span>
                    <span>
                      {popularContents?.articles &&
                        new Date(
                          popularContents?.articles[3]?.createdDateTime || ""
                        ).toLocaleDateString("en-US", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                    </span>
                  </div>
                  <a
                    className="h6 m-0"
                    href={
                      "/#/article?articleid=" +
                      (popularContents?.articles &&
                        popularContents?.articles[3].id)
                    }
                  >
                    {popularContents?.articles &&
                      popularContents?.articles[3].title}
                  </a>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedSlider;
