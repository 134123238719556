import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import { HashRouter, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Admissions from "./components/Admissions/Admissions";
import AddCollege from "./components/AddCollege/AddCollege";
import AddArticle from "./components/AddArticle/AddArticle";
import Topbar from "./components/Topbar/Topbar";
import Navbar from "./components/Navbar/Navbar";
import Contact from "./components/Contact/Contact";
import Country from "./components/Country/Country";
import State from "./components/State/State";
import StateAdmission from "./components/StateAdmission/StateAdmission";
import StateCourses from "./components/StateCourses/StateCourses";
import Colleges from "./components/Colleges/Colleges";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Dashboard from "./components/Dashboard/Dashboard";
import Article from "./components/Article/Article";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions/TermsConditions";
import Categories from "./components/Categories/Categories";
import University from "./components/University/University";

function App() {
  return (
    <div className="App">
      <Topbar />
      <Navbar />
      <HashRouter>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/register" element={<Home />} />
          <Route path="/" element={<Home />} />
          <Route path="/admissions" element={<Admissions />} />
          <Route path="/addarticle" element={<AddArticle />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/article" element={<Article />} />
          <Route path="/country" element={<Country />} />
          <Route path="/state" element={<State />} />
          <Route path="/stateadmission" element={<StateAdmission />} />
          <Route path="/course-state" element={<StateCourses />} />
          <Route path="/college" element={<Colleges />} />
          <Route path="/university" element={<University />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsConditions />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/addcollege"
            element={
              <ProtectedRoute>
                <AddCollege />
              </ProtectedRoute>
            }
          />
        </Routes>
      </HashRouter>
      <Footer />
    </div>
  );
}

export default App;
