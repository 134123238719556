import React, { FC } from "react";
import "./BreadCrumb.css";

export interface IBreads {
  title: string;
  link: string;
}

interface BreadCrumbProps {
  links: IBreads[];
  title: string;
}

const BreadCrumb: FC<BreadCrumbProps> = (props) => {
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <nav className="breadcrumb bg-transparent m-0 p-0">
            {props?.links?.map((e, index) => (
              <a className="breadcrumb-item" href={e?.link} key={index}>
                {e.title}
              </a>
            ))}

            <span className="breadcrumb-item active">{props?.title}</span>
          </nav>
        </div>
      </div>
    </>
  );
};

export default BreadCrumb;
