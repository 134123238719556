import React, { FC, useEffect, useState } from "react";
import "./Article.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Article as art } from "../../interfaces";
import { useSearchParams } from "react-router-dom";
import parse from "html-react-parser";

interface ArticleProps {}

const Article: FC<ArticleProps> = () => {
  const [articler, setArticler] = useState<art>();
  const url = `https://apis.nuhman.com/`;

  const [searchParams] = useSearchParams();
  const aid = searchParams.get("articleid");

  useEffect(() => {
    axios
      .get(url + "apis/education/article/" + aid)
      .then((res) => {
        setArticler(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Helmet>
        <title>{"College Searchers.com - " + articler?.title}</title>
        <meta name="description" content={articler?.description} />
        <meta
          property="og:title"
          content={"College Searchers.com - " + articler?.title}
        />
        <meta property="og:description" content={articler?.description} />
      </Helmet>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="overlay position-relative bg-light rresult">
                <div className="mb-3 rresult">
                  <h3 className="mb-4">{articler?.title}</h3>
                  <p>
                    {articler?.image && (
                      <img
                        src={
                          "https://collegesearchers.com/images/" +
                          articler.image
                        }
                      />
                    )}
                  </p>
                  <div>{parse(articler?.description || "")}</div>
                </div>
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Article;
