import React, { FC, useState } from "react";
import "./Topbar.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

interface TopbarProps {}

const Topbar: FC<TopbarProps> = () => {
  const today = new Date();
  let text = today.toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const options = {
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 2,
      },
    },
    nav: false,
    dots: true,
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row align-items-center bg-light px-lg-5">
          <div className="col-12 col-md-8">
            <div className="d-flex justify-content-between">
              <div
                className="bg-primary text-white text-center py-2"
                id="trending"
              >
                Trending
              </div>
              <OwlCarousel
                className="owl-theme section"
                loop
                margin={10}
                autoplay
                {...options}
              >
                {/* 1 */}
                <div className="review item margintops">
                  <p>
                    <a href="/#/admissions">Kerala Entrance Previous...</a>
                  </p>
                </div>

                {/* 2 */}
                <div className="review item margintops">
                  <p>
                    <a href="/#/admissions">Kerala Engineering Medical...</a>
                  </p>
                </div>

                <div className="review item margintops">
                  <p>
                    <a href="/#/admissions">Kerala Engineering Medical...</a>
                  </p>
                </div>

                <div className="review item margintops">
                  <p>
                    <a href="/#/admissions">Kerala Engineering Medical...</a>
                  </p>
                </div>

                <div className="review item margintops">
                  <p>
                    <a href="/#/admissions">Kerala Engineering Medical...</a>
                  </p>
                </div>
              </OwlCarousel>
            </div>
          </div>
          <div className="col-md-4 text-right d-none d-md-block">{text}</div>
        </div>
        <div className="row align-items-center py-2 px-lg-5">
          <div className="col-lg-4">
            <a href="" className="navbar-brand d-none d-lg-block">
              <h1 className="m-0 display-5 text-uppercase sitetitle">
                <span className="text-primary sitetitle">College</span>Searchers
              </h1>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
