import React, { FC, useEffect, useState } from "react";
import "./Colleges.css";
import { useSearchParams } from "react-router-dom";
import {
  amenityCategory,
  CollegeResp,
  Country,
  course,
  EduCatCourses,
  educationCategoryDAO,
  State,
} from "../../interfaces";
import axios from "axios";
import { Helmet } from "react-helmet";
import BreadCrumb from "../BreadCrumb/BreadCrumb";

interface CollegesProps {}

const Colleges: FC<CollegesProps> = () => {
  const [college, setCollege] = useState<CollegeResp>();
  const [showCourseForm, setShowCourseForm] = useState<boolean>();
  const [message, setMessage] = useState<string>();
  const [countries, setCountries] = useState<Country[]>();
  const [states, setStates] = useState<State[]>();
  const [amenityCategories, setAmenityCategories] =
    useState<amenityCategory[]>();
  const url = `https://apis.nuhman.com/`;

  const [searchParams] = useSearchParams();
  const cid = searchParams.get("collegeid");

  useEffect(() => {
    console.log("reloading");
    axios
      .get(url + "apis/education/college/" + cid)
      .then((res) => {
        setCollege(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + "apis/education/amenities")
      .then((res) => {
        setAmenityCategories(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + `apis/education/countries`)
      .then((res) => {
        setCountries(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + `apis/education/states`)
      .then((res) => {
        setStates(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function showAddCourseForm(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    setShowCourseForm(true);
  }

  function submitCourseForm(event: React.FormEvent<HTMLFormElement>): void {
    throw new Error("Function not implemented.");
  }

  function setName(value: string): void {
    throw new Error("Function not implemented.");
  }

  function setEmail(value: string): void {
    throw new Error("Function not implemented.");
  }

  function setSubject(value: string): void {
    throw new Error("Function not implemented.");
  }

  function submitAmenityForm(event: React.FormEvent<HTMLFormElement>): void {
    throw new Error("Function not implemented.");
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      <Helmet>
        <title>{"College Searchers.com - " + college?.name}</title>
        <meta
          property="og:title"
          content={"College Searchers.com - " + college?.name}
        />
        <meta
          property="og:description"
          content={
            "College Searchers.com - " +
            college?.name +
            ". Courses, Articles, Facilities and news"
          }
        />
        <meta
          property="description"
          content={
            "College Searchers.com - " +
            college?.name +
            ". Courses, Articles, Facilities and news"
          }
        />
      </Helmet>

      <BreadCrumb
        links={[
          { link: "/#/home", title: "Home" },
          { link: "/#/country", title: "Country" },
          {
            link:
              "/#/state?countryid=" +
              college?.state?.country?.id +
              "&country=" +
              college?.state?.country?.name,
            title: "State",
          },
        ]}
        title={" " + college?.name}
      />
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mmargin">
              <div className="bg-light rresults">
                <div className="mb-3 rresult">
                  <h2 className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    {college?.name}{" "}
                  </h2>
                  <h3 className="pt">Courses</h3>

                  {college?.educatCourselist?.map(
                    (ctry: educationCategoryDAO, index) => (
                      <div key={index}>
                        <p>{ctry.name}</p>
                        {ctry.courses?.map((course: course, index) => (
                          <>
                            <div key={index}>
                              <a
                                href={
                                  "/#/admissions?adid=" +
                                  ctry.id +
                                  "&csid=" +
                                  cid
                                }
                              >
                                {course.name}
                              </a>
                            </div>
                          </>
                        ))}
                      </div>
                    )
                  )}
                  <p className="pt">
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={showAddCourseForm}
                    >
                      Add a new Course
                    </button>
                  </p>
                  {showCourseForm && (
                    <div>
                      <form
                        name="sentMessage"
                        id="contactForm"
                        onSubmit={submitCourseForm}
                      >
                        <div className="form-row">
                          <div className="col-md-6">
                            <div className="control-group">
                              <input
                                type="text"
                                className="form-control p-4"
                                id="name"
                                name="name"
                                onChange={(event) =>
                                  setName(event.target.value)
                                }
                                placeholder="Course Name"
                                required
                                data-validation-required-message="Please enter your name"
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="control-group">
                              <input
                                type="email"
                                className="form-control p-4"
                                id="email"
                                name="email"
                                onChange={(event) =>
                                  setEmail(event.target.value)
                                }
                                placeholder="Your Email"
                                required
                                data-validation-required-message="Please enter your email"
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </div>
                        </div>
                        <div className="control-group">
                          <input
                            type="text"
                            className="form-control p-4"
                            id="subject"
                            name="subject"
                            placeholder="Subject"
                            onChange={(event) => setSubject(event.target.value)}
                            required
                            data-validation-required-message="Please enter a subject"
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                        <div className="control-group">
                          <textarea
                            className="form-control"
                            rows={4}
                            id="message"
                            name="message"
                            placeholder="Course Description"
                            onChange={(event) => setMessage(event.target.value)}
                            required
                            data-validation-required-message="Please enter your message"
                          ></textarea>
                          <p className="help-block text-danger"></p>
                        </div>
                        <div>
                          <button
                            className="btn btn-primary font-weight-semi-bold px-4"
                            style={{ height: 50 }}
                            type="submit"
                            id="sendMessageButton"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  )}
                  <p className="pt">
                    Disclaimer: Information on this website may not be accurate,
                    please verify with college official website
                  </p>

                  <h4 className="pt">
                    Nearby Amenities around {college?.name}
                  </h4>
                  <div className="st">
                    <p>Nothing added</p>
                    <p>
                      <button
                        type="button"
                        className="btn btn-outline-success btn-sm"
                        onClick={showAddCourseForm}
                      >
                        Add a new Amenity
                      </button>
                    </p>

                    <form
                      name="addAmenity"
                      id="addAmenity"
                      onSubmit={submitAmenityForm}
                    >
                      <div className="control-group wid">
                        <p>
                          {" "}
                          Image:
                          <input
                            id="file3"
                            type="file"
                            name="image"
                            onChange={handleFileChange}
                          />
                        </p>
                      </div>

                      <div className="control-group wid">
                        <p>
                          Select Amenity Category:{" "}
                          <select name="articleCategoryId" className=" p-2">
                            <option value={0}>Select</option>
                            {amenityCategories?.map(
                              (ctry: amenityCategory, index) => (
                                <option key={index} value={ctry.id}>
                                  {ctry.name}
                                </option>
                              )
                            )}
                          </select>
                        </p>
                      </div>

                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="control-group">
                            <input
                              type="text"
                              className="form-control p-4"
                              id="name"
                              name="name"
                              onChange={(event) => setName(event.target.value)}
                              placeholder="Amenity Title"
                              required
                              data-validation-required-message="Please enter Amenity title"
                            />
                            <p className="help-block text-danger"></p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="control-group">
                            <input
                              type="email"
                              className="form-control p-4"
                              id="email"
                              name="email"
                              onChange={(event) => setEmail(event.target.value)}
                              placeholder="Your Email"
                              required
                              data-validation-required-message="Please enter your email"
                            />
                            <p className="help-block text-danger"></p>
                          </div>
                        </div>
                      </div>

                      <div className="control-group">
                        <textarea
                          className="form-control"
                          rows={4}
                          id="message"
                          name="message"
                          placeholder="Amenity Description"
                          onChange={(event) => setMessage(event.target.value)}
                          required
                          data-validation-required-message="Please enter your message"
                        ></textarea>
                        <p className="help-block text-danger"></p>
                      </div>

                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="control-group">
                            <input
                              type="text"
                              className="form-control p-4"
                              id="name"
                              name="name"
                              onChange={(event) => setName(event.target.value)}
                              placeholder="Contact Name"
                              required
                              data-validation-required-message="Please enter Amenity title"
                            />
                            <p className="help-block text-danger"></p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="control-group">
                            <input
                              type="text"
                              className="form-control p-4"
                              id="name"
                              name="name"
                              onChange={(event) => setName(event.target.value)}
                              placeholder="Contact Phone"
                              required
                              data-validation-required-message="Please enter Amenity title"
                            />
                            <p className="help-block text-danger"></p>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="control-group">
                            <input
                              type="text"
                              className="form-control p-4"
                              id="name"
                              name="name"
                              onChange={(event) => setName(event.target.value)}
                              placeholder="Contact Email"
                              required
                              data-validation-required-message="Please enter Amenity title"
                            />
                            <p className="help-block text-danger"></p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="control-group">
                            <input
                              type="text"
                              className="form-control p-4"
                              id="name"
                              name="name"
                              onChange={(event) => setName(event.target.value)}
                              placeholder="Landmark"
                              required
                              data-validation-required-message="Please enter Amenity title"
                            />
                            <p className="help-block text-danger"></p>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="control-group">
                            <input
                              type="text"
                              className="form-control p-4"
                              id="name"
                              name="name"
                              onChange={(event) => setName(event.target.value)}
                              placeholder="Address 1"
                              required
                              data-validation-required-message="Please enter Amenity title"
                            />
                            <p className="help-block text-danger"></p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="control-group">
                            <input
                              type="text"
                              className="form-control p-4"
                              id="name"
                              name="name"
                              onChange={(event) => setName(event.target.value)}
                              placeholder="Address 2"
                              required
                              data-validation-required-message="Please enter Amenity title"
                            />
                            <p className="help-block text-danger"></p>
                          </div>
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="col-md-6">
                          <div className="control-group">
                            <input
                              type="text"
                              className="form-control p-4"
                              id="name"
                              name="name"
                              onChange={(event) => setName(event.target.value)}
                              placeholder="City"
                              required
                              data-validation-required-message="Please enter Amenity title"
                            />
                            <p className="help-block text-danger"></p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="control-group">
                            <input
                              type="text"
                              className="form-control p-4"
                              id="name"
                              name="name"
                              onChange={(event) => setName(event.target.value)}
                              placeholder="Address 2"
                              required
                              data-validation-required-message="Please enter Amenity title"
                            />
                            <p className="help-block text-danger"></p>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="bg-light rresultser">
                <div className="mb-3 rresult">
                  <div className="">
                    <h5> {college?.address1}</h5>
                    <h5> {college?.city}</h5>
                    <h5> {college?.zip}</h5>
                    <h5> {college?.state?.name}</h5>
                    <h5> {college?.country?.name}</h5>
                  </div>
                  <div className="">
                    <h5>
                      {" "}
                      {college?.website && (
                        <>
                          Website:{" "}
                          <a href={college?.website}>{college?.website}</a>
                        </>
                      )}
                    </h5>
                  </div>
                  <div>
                    <script
                      type="text/javascript"
                      src="https://udbaa.com/bnr.php?section=General&pub=572899&format=300x250&ga=g"
                    ></script>
                    <noscript>
                      <a
                        href="https://yllix.com/publishers/572899"
                        target="_blank"
                      >
                        <img
                          src="//ylx-aff.advertica-cdn.com/pub/300x250.png"
                          className="ylixs"
                          alt="ylliX - Online Advertising Network"
                        />
                      </a>
                    </noscript>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Colleges;
