import React, { FC, useEffect, useState } from "react";
import "./State.css";
import { Article, Articles, State as ste, University } from "../../interfaces";
import { Helmet } from "react-helmet";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

interface StateProps {}

const State: FC<StateProps> = () => {
  const [countries, setCountries] = useState<ste[]>();
  const [universities, setUniversities] = useState<University[]>();
  const [articles, setArticles] = useState<Articles>({});
  const url = `https://apis.nuhman.com/`;

  const [searchParams] = useSearchParams();

  const ctid = searchParams.get("countryid");
  const cn = searchParams.get("country");

  useEffect(() => {
    console.log("reloading");
    axios
      .get(url + "apis/education/states?countryId=" + ctid + "&bar=true")
      .then((res) => {
        setCountries(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + "apis/education/articles?pageSize=20&countryId=" + ctid)
      .then((res) => {
        setArticles(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + "apis/education/universities?countryId=" + ctid)
      .then((res) => {
        setUniversities(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Helmet>
        <title>
          College Searchers.com - Education Resources by States in {cn}
        </title>
        <meta
          property="og:title"
          content={
            "College Searchers.com - Education Resources by States in " + cn
          }
        />
        <meta
          property="description"
          content={
            "Education Resources by States in " +
            cn +
            ". Courses, Colleges, Articles, Admissions"
          }
        />
        <meta
          property="og:description"
          content={
            "Education Resources by States in " +
            cn +
            ". Courses, Colleges, Articles, Admissions"
          }
        />
      </Helmet>
      <BreadCrumb
        links={[
          { link: "/#/home", title: "Home" },
          { link: "/#/country", title: "Country" },
        ]}
        title={"Education Resources by State in " + cn}
      />

      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mmargin">
              <div className="bg-light rresults">
                <div className="mb-3 rresult">
                  {(countries?.length || 0 > 0) && (
                    <h2 className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                      Education Resources in {cn} by States
                    </h2>
                  )}

                  {countries && (
                    <div className="position-relative overflow-hidden mb-3 cat">
                      {countries?.map((ctry: ste, index) => (
                        <>
                          <div className="col-sm-4 intdisp">
                            <p>
                              <a
                                key={index}
                                href={
                                  "/#/stateadmission?stateid=" +
                                  ctry.id +
                                  "&countryid=" +
                                  ctid +
                                  "&country=" +
                                  cn +
                                  "&state=" +
                                  ctry.name
                                }
                              >
                                {ctry.name}
                              </a>
                            </p>
                          </div>
                        </>
                      ))}
                    </div>
                  )}

                  {(universities?.length || 0 > 0) && (
                    <h2 className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                      Universities in {cn}
                    </h2>
                  )}

                  {universities && (
                    <div className="position-relative overflow-hidden mb-3 cat">
                      {universities?.map((ctry: University, index) => (
                        <>
                          <div className="col-sm-6 intdisp">
                            <p>
                              <a
                                key={index}
                                href={
                                  "/#/university?universityid=" +
                                  ctry.id +
                                  "&countryid=" +
                                  ctid +
                                  "&country=" +
                                  cn
                                }
                              >
                                {ctry.name}
                              </a>
                            </p>
                          </div>
                        </>
                      ))}
                    </div>
                  )}

                  {(articles?.articles?.length || 0 > 0) && (
                    <h2 className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                      Articles related to Education in {cn}
                    </h2>
                  )}

                  {articles &&
                    articles?.articles?.map((articleRes: Article, index) => (
                      <>
                        <div className="col-sm-6 intdisp intdisper">
                          <div className="d-flex mb-3">
                            <img
                              src={
                                "https://collegesearchers.com/images/" +
                                  articleRes.image100x100 || "test"
                              }
                              className="imgwhcover"
                            />
                            <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                              <div className="mb-1 headerthirteen">
                                <span>
                                  {new Date(
                                    articleRes?.createdDateTime || ""
                                  ).toLocaleDateString("en-US", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  })}
                                </span>
                              </div>
                              <a
                                className="h6 m-0"
                                href={"/#/article?articleid=" + articleRes?.id}
                              >
                                {articleRes?.title}
                              </a>
                              <p></p>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default State;
