import React, { FC, useEffect, useState } from "react";
import "./Register.css";
import { educationCategory } from "../../interfaces";
import axios from "axios";
import CategorySlider1 from "../CategorySlider1/CategorySlider1";
import CategorySlider2 from "../CategorySlider2/CategorySlider2";
import FeaturedSlider from "../FeaturedSlider/FeaturedSlider";
import Sidebar from "../Sidebar/Sidebar";
import TopSlider from "../TopSlider/TopSlider";

interface RegisterProps {}

const Register: FC<RegisterProps> = () => {
  const [categories, setCategories] = useState<educationCategory[]>();
  const url = `https://apis.nuhman.com/`;

  useEffect(() => {
    axios
      .get(url + `apis/education/education-categories`)
      .then((res) => {
        setCategories(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <TopSlider />
      <>
        <div className="container-fluid py-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mmargin">
                <div className="bg-light rresults">
                  <div className="mb-3 rresult">
                    <h2 className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                      Login
                    </h2>

                    <div className="position-relative overflow-hidden mb-3 cat">
                      <div className="control-group wid">
                        <input
                          type="text"
                          className="form-control p-4"
                          id="name"
                          placeholder="Your Name"
                          required
                          data-validation-required-message="Please enter your name"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                      <div className="control-group wid">
                        <input
                          type="text"
                          className="form-control p-4"
                          id="name"
                          placeholder="Your Name"
                          required
                          data-validation-required-message="Please enter your name"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                      <div>
                        <button
                          className="btn btn-primary font-weight-semi-bold px-4"
                          style={{ height: 50 }}
                          type="submit"
                          id="sendMessageButton"
                        >
                          Submit
                        </button>
                      </div>
                      <div>
                        <p></p>
                        <p>
                          <a href="/#/forgot-password">Forgot Password</a> or{" "}
                          <a href="/#/login">Login</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="bg-light rresultser">
                  <div className="mb-3 rresult">
                    <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                      <h3 className="m-0">Articles</h3>
                      <a
                        className="text-secondary font-weight-medium text-decoration-none"
                        href=""
                      >
                        View All
                      </a>
                    </div>
                    <div>
                      {categories
                        ?.slice(0, 5)
                        .map((ctry: educationCategory, index) => (
                          <div
                            className="position-relative overflow-hidden mb-3 cat"
                            key={index}
                          >
                            <h5>
                              <a href={"/#/categories?catid=" + ctry.id}>
                                {" "}
                                {ctry.name}
                              </a>{" "}
                            </h5>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      <FeaturedSlider />
      <CategorySlider1 />
      <CategorySlider2 />
      <Sidebar isLoading={true} />
    </>
  );
};

export default Register;
