import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { isUserLoggedIn } from "../../store/sessionManager";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  console.log("Logged in check" + isUserLoggedIn());
  if (!isUserLoggedIn()) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default ProtectedRoute;
