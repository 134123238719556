import React, { FC, useState } from "react";
import "./Categories.css";
import FeaturedSlider from "../FeaturedSlider/FeaturedSlider";
import MainSlider from "../MainSlider/MainSlider";
import TopSlider from "../TopSlider/TopSlider";
import CategoriesList from "../CategoriesList/CategoriesList";
import { useSearchParams } from "react-router-dom";

interface CategoriesProps {}

const Categories: FC<CategoriesProps> = () => {
  const [cat, setCat] = useState(true);
  const [catId, setCatId] = useState<number>(0);
  const [searchParams] = useSearchParams();
  const aid = searchParams.get("catid");
  const y = Number(aid);

  return (
    <div className="Categories">
      <CategoriesList catId={y} />
    </div>
  );
};
export default Categories;
