import React, { FC, useEffect, useState } from "react";
import "./Country.css";
import axios from "axios";
import { Country as ctry } from "../../interfaces";
import { Helmet } from "react-helmet";
import BreadCrumb from "../BreadCrumb/BreadCrumb";

interface CountryProps {}

const Country: FC<CountryProps> = () => {
  const [countries, setCountries] = useState<ctry[]>();
  const url = `https://apis.nuhman.com/`;

  useEffect(() => {
    console.log("reloading");
    axios
      .get(url + `apis/education/countries?bar=false`)
      .then((res) => {
        setCountries(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Helmet>
        <title>College Searchers.com - Education Resources by Countries</title>
        <meta
          property="og:title"
          content={"College Searchers.com - Education Resources by Countries"}
        />
        <meta
          property="description"
          content={
            "Education Resources by Countries. Courses, Colleges, Admission, Articles and Other resources"
          }
        />
        <meta
          property="og:description"
          content={
            "Education Resources by Countries. Courses, Colleges, Admission, Articles and Other resources"
          }
        />
      </Helmet>
      <BreadCrumb
        links={[{ link: "/#/home", title: "Home" }]}
        title={"Education Resources by Countries"}
      />

      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="overlay position-relative bg-light rresult">
                <div className="mb-3 rresult">
                  <h3 className="mb-4">Education Resources by Countries</h3>

                  <div>
                    {countries?.map((ctry: ctry, index) => (
                      <div className="col-sm-4 intdisp">
                        <p>
                          <a
                            key={index}
                            href={
                              "/#/state?countryid=" +
                              ctry.id +
                              "&country=" +
                              ctry.name
                            }
                          >
                            {ctry.name}
                          </a>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Country;
