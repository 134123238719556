import React, { FC } from "react";
import "./TopSlider.css";

interface TopSliderProps {}

const TopSlider: FC<TopSliderProps> = () => {
  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="owl-carousel owl-carousel-2 carousel-item-3 position-relative">
            <div className="d-flex">
              <img src="img/news-100x100-1.jpg" className="imgwhc" />
              <div className="d-flex align-items-center bg-light px-3 heighteighty">
                <a className="text-secondary font-weight-semi-bold" href="">
                  Lorem ipsum dolor sit amet consec adipis elit
                </a>
              </div>
            </div>
            <div className="d-flex">
              <img src="img/news-100x100-2.jpg" className="imgwhc" />
              <div className="d-flex align-items-center bg-light px-3 heighteighty">
                <a className="text-secondary font-weight-semi-bold" href="">
                  Lorem ipsum dolor sit amet consec adipis elit
                </a>
              </div>
            </div>
            <div className="d-flex">
              <img src="img/news-100x100-3.jpg" className="imgwhc" />
              <div className="d-flex align-items-center bg-light px-3 heighteighty">
                <a className="text-secondary font-weight-semi-bold" href="">
                  Lorem ipsum dolor sit amet consec adipis elit
                </a>
              </div>
            </div>
            <div className="d-flex">
              <img src="img/news-100x100-4.jpg" className="imgwhc" />
              <div className="d-flex align-items-center bg-light px-3 heighteighty">
                <a className="text-secondary font-weight-semi-bold" href="">
                  Lorem ipsum dolor sit amet consec adipis elit
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopSlider;
