import React, { FC } from "react";
import "./CategorySlider2.css";

interface CategorySlider2Props {}

const CategorySlider2: FC<CategorySlider2Props> = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 py-3">
              <div className="bg-light py-2 px-4 mb-3">
                <h3 className="m-0">Entertainment</h3>
              </div>
              <div className="owl-carousel owl-carousel-3 carousel-item-2 position-relative">
                <div className="position-relative">
                  <img
                    className="img-fluid w-100 imgwhc"
                    src="img/news-500x280-6.jpg"
                  />
                  <div className="overlay position-relative bg-light">
                    <div className="mb-2 fontsizethirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h4 m-0" href="">
                      Sanctus amet sed ipsum lorem
                    </a>
                  </div>
                </div>
                <div className="position-relative">
                  <img
                    className="img-fluid w-100 imgwhc"
                    src="img/news-500x280-5.jpg"
                  />
                  <div className="overlay position-relative bg-light">
                    <div className="mb-2 fontsizethirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h4 m-0" href="">
                      Sanctus amet sed ipsum lorem
                    </a>
                  </div>
                </div>
                <div className="position-relative">
                  <img
                    className="img-fluid w-100 imgwhc"
                    src="img/news-500x280-4.jpg"
                  />
                  <div className="overlay position-relative bg-light">
                    <div className="mb-2 fontsizethirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h4 m-0" href="">
                      Sanctus amet sed ipsum lorem
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 py-3">
              <div className="bg-light py-2 px-4 mb-3">
                <h3 className="m-0">Sports</h3>
              </div>
              <div className="owl-carousel owl-carousel-3 carousel-item-2 position-relative">
                <div className="position-relative">
                  <img
                    className="img-fluid w-100 imgwhc"
                    src="img/news-500x280-3.jpg"
                  />
                  <div className="overlay position-relative bg-light">
                    <div className="mb-2 fontsizethirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h4 m-0" href="">
                      Sanctus amet sed ipsum lorem
                    </a>
                  </div>
                </div>
                <div className="position-relative">
                  <img
                    className="img-fluid w-100 imgwhc"
                    src="img/news-500x280-2.jpg"
                  />
                  <div className="overlay position-relative bg-light">
                    <div className="mb-2 fontsizethirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h4 m-0" href="">
                      Sanctus amet sed ipsum lorem
                    </a>
                  </div>
                </div>
                <div className="position-relative">
                  <img
                    className="img-fluid w-100 imgwhc"
                    src="img/news-500x280-1.jpg"
                  />
                  <div className="overlay position-relative bg-light">
                    <div className="mb-2 fontsizethirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h4 m-0" href="">
                      Sanctus amet sed ipsum lorem
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategorySlider2;
