import React, { FC, useEffect, useState } from "react";
import "./StateAdmission.css";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import {
  admission,
  admissionsResponse,
  Article,
  Articles,
  CollegeResponse,
  course,
  courseResponse,
} from "../../interfaces";

interface StateAdmissionProps {}

const StateAdmission: FC<StateAdmissionProps> = () => {
  const [admissionEvents, setAdmissionEvents] = useState<admissionsResponse>();
  const [coursesList, setCoursesList] = useState<courseResponse>();
  const [colleges, setColleges] = useState<CollegeResponse>();
  const [articles, setArticles] = useState<Articles>();
  const url = `https://apis.nuhman.com/`;

  const [searchParams] = useSearchParams();

  const ctid = searchParams.get("stateid");
  const cnid = searchParams.get("countryid");
  const st = searchParams.get("state");
  const cn = searchParams.get("country");

  useEffect(() => {
    console.log("reloading");
    axios
      .get(url + "apis/education/admissions?stateid=" + ctid)
      .then((res) => {
        setAdmissionEvents(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + "apis/education/courses?stateid=" + ctid)
      .then((res) => {
        setCoursesList(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + "apis/education/colleges?pageSize=300&stateId=" + ctid)
      .then((res) => {
        setColleges(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + "apis/education/articles?pageSize=5&stateId=" + ctid)
      .then((res) => {
        setArticles(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const test = "/#/state?countryid=" + cnid + "&country=" + cn;

  return (
    <>
      <Helmet>
        <title>
          College Searchers.com - Admissions in {st} state, {cn} country
        </title>
        <meta
          name="description"
          content={
            "College Searchers.com - Admissions in " +
            st +
            "state, " +
            cn +
            " country"
          }
        />
        <meta
          property="og:title"
          content={
            "College Searchers.com - Admissions in " +
            st +
            "state, " +
            cn +
            " country"
          }
        />
        <meta
          property="og:description"
          content={
            "College Searchers.com - Admissions in " +
            st +
            "state, " +
            cn +
            " country"
          }
        />
      </Helmet>
      <BreadCrumb
        links={[
          { link: "/#/home", title: "Home" },
          { link: "/#/country", title: "Country" },
          {
            link: test,
            title: "State",
          },
        ]}
        title={"Education Resources in " + st + " state, " + cn}
      />

      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="overlay position-relative bg-light rresult">
                <div className="mb-3 rresult">
                  {(admissionEvents?.admissions?.length || 0 > 0) && (
                    <h2 className="mb-4">
                      Admissions in {st} state, {cn}
                    </h2>
                  )}
                  {admissionEvents?.admissions?.map(
                    (ctry: admission, index) => (
                      <>
                        <div key={index}>
                          <p>
                            {ctry?.state?.name} --{" "}
                            {ctry?.educationCategory?.name}
                          </p>
                          <a href={"/#/admissions?adid=" + ctry.id}>
                            {ctry.name}
                          </a>
                        </div>
                      </>
                    )
                  )}
                  <div></div>
                </div>

                <div className="mb-3 rresult">
                  {(coursesList?.courses?.length || 0 > 0) && (
                    <h2 className="mb-4">
                      Courses in {st} state, {cn}
                    </h2>
                  )}
                  {coursesList?.courses?.map((ctry: course, index) => (
                    <>
                      <div key={index}>
                        <a
                          href={
                            "/#/course-state?stateid=" +
                            ctid +
                            "&courseid=" +
                            ctry.id +
                            "&state=" +
                            st +
                            "&course=" +
                            ctry.name
                          }
                        >
                          {ctry?.name}
                        </a>
                      </div>
                    </>
                  ))}
                  <div></div>
                </div>

                <div className="mb-3 rresult">
                  {(colleges?.colleges?.length || 0 > 0) && (
                    <h2 className="mb-4">
                      Colleges in {st} state, {cn}
                    </h2>
                  )}
                  {colleges?.colleges?.map((ctry: course, index) => (
                    <>
                      <div key={index}>
                        <a
                          href={
                            "/#/college?stateid=" +
                            ctid +
                            "&collegeid=" +
                            ctry.id +
                            "&state=" +
                            st +
                            "&course=" +
                            ctry.name
                          }
                        >
                          {ctry.name}
                        </a>
                      </div>
                    </>
                  ))}
                  <div></div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="position-relative bg-light rresult">
                <h3>Articles</h3>
                {articles?.articles?.map((ctry: Article, index) => (
                  <>
                    <div key={index}>
                      <a href={"/#/article?articleid=" + ctry.id}>
                        {ctry.title}
                      </a>
                      <div>
                        {parse(ctry.shortDescription || "")} ...
                        <a href={"/#/article?articleid=" + ctry.id}>More</a>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StateAdmission;
