import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import "./Admissions.css";
import {
  admission,
  admissionsResponse,
  courseResponse,
  lastRankDetails,
  studentCat,
} from "../../interfaces";
import axios from "axios";
import { Helmet } from "react-helmet";
import { usePDF } from "react-to-pdf";
import FollowUs from "../FollowUs/FollowUs";
import NewsLetter from "../NewsLetter/NewsLetter";
import { useSearchParams } from "react-router-dom";

interface AdmissionsProps {}

const Admissions: FC<AdmissionsProps> = () => {
  const [eventId, setEventId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [submitProcessing, setSubmitProcessing] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [pageIndex, setPageIndex] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [admissionEvents, setAdmissionEvents] = useState<admissionsResponse>();
  const [coursesList, setCoursesList] = useState<courseResponse>();
  const [collegeRankList, setCollegeRankList] = useState<lastRankDetails[]>([]);
  const [studentCat, setStudentCat] = useState<studentCat[]>([]);
  const [loadrank, setLoadrank] = useState(1);

  const { toPDF, targetRef } = usePDF({ filename: "page.pdf" });

  const [searchParams] = useSearchParams();

  const adId = searchParams.get("adid");
  const ctId = searchParams.get("ctid");
  const csId = searchParams.get("csid");
  const url = `https://apis.nuhman.com/`;
  //const url = `http://localhost:9552/`;

  function handleMenuOnChange(event: ChangeEvent<HTMLSelectElement>): void {
    console.log(event.target.value);
    setEventId(event.target.value);

    axios
      .get(
        url +
          `apis/education/courses?pageSize=200&eventId=` +
          event.target.value
      )
      .then((res) => {
        setCoursesList(res?.data);
      })
      .catch((err) => console.log(err));

    setPageIndex(0);
    setCollegeRankList([]);
    console.log(eventId);
    //throw new Error("Function not implemented.");
  }

  function addCourse(event: ChangeEvent<HTMLSelectElement>): void {
    setCourseId(event.target.value);
    setPageIndex(0);
    setCollegeRankList([]);
    //throw new Error("Function not implemented.");
  }

  function loadLastRankDetails() {
    axios
      .get(url + `apis/education/admissions`)
      .then((res) => {
        setAdmissionEvents(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + `apis/education/courses?pageSize=60`)
      .then((res) => {
        setCoursesList(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + `apis/education/student-categories`)
      .then((res) => {
        setStudentCat(res?.data);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    console.log("reloading");
    loadLastRankDetails();
  }, []);

  useEffect(() => {
    if (adId && loadrank == 1) {
      console.log("TEst");
      //setEventId(adId ? adId : "");
      //setDisabled(true);
      //fetchDataLastRank();
      //setDisabled(true);
      setLoadrank(2);
      setDisabled(true);
      const rcsid = csId ? csId : "";

      axios
        .get(
          url +
            "apis/education/last-rank?studentCatId=" +
            categoryName +
            "&admissionEventId=" +
            adId +
            "&courseId=" +
            rcsid +
            "&pageNo=" +
            pageIndex +
            "&pageSize=50" +
            "&sortBy=rank"
        )
        .then((res) => {
          setCollegeRankList(res?.data?.lastRankDetails);

          if (res?.data?.totalPages - 1 == res?.data?.currentPage) {
            setDisabled(false);
          }
        })
        .catch((err) => console.log(err));
      setSubmitProcessing(true);
      setPageIndex(pageIndex + 1);
    }
  }, []);

  function addCategory(event: ChangeEvent<HTMLSelectElement>): void {
    console.log(event.target.value);
    setCategoryName(event.target.value);
    setPageIndex(0);
    setCollegeRankList([]);
    console.log(categoryName);
  }

  const fetchDataLastRank = useCallback(async () => {
    axios
      .get(
        url +
          "apis/education/last-rank?studentCatId=" +
          categoryName +
          "&admissionEventId=" +
          eventId +
          "&courseId=" +
          courseId +
          "&pageNo=" +
          pageIndex +
          "&pageSize=50" +
          "&sortBy=rank"
      )
      .then((res) => {
        setCollegeRankList((lastRankDetails: lastRankDetails[]) => [
          ...lastRankDetails,
          ...res?.data?.lastRankDetails,
        ]);

        if (res?.data?.totalPages - 1 == res?.data?.currentPage) {
          setDisabled(false);
        }
      })
      .catch((err) => console.log(err));
    setSubmitProcessing(true);
    setPageIndex(pageIndex + 1);
  }, [pageIndex, eventId, categoryName, submitProcessing, collegeRankList]);

  function handleSubmitForm(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    setDisabled(true);
    console.log(eventId);
    console.log(categoryName);
    fetchDataLastRank();
  }

  function getRank(
    collegeLastRanks: Map<string, number> | undefined
  ): React.ReactNode {
    return JSON.stringify(
      Object.values(collegeLastRanks != undefined ? collegeLastRanks : {})
    );
    //throw new Error("Function not implemented.");
  }

  return (
    <>
      <Helmet>
        <title>
          College Searchers.com - Last Rank Details of Kerala Engineering 2023
        </title>
      </Helmet>
      <div className="container-fluid">
        <div className="container">
          <nav className="breadcrumb bg-transparent m-0 p-0">
            <a className="breadcrumb-item" href="#">
              Home
            </a>
            <a className="breadcrumb-item" href="#">
              Colleges
            </a>
            <a className="breadcrumb-item" href="/#/admissions">
              Admissions
            </a>
            <span className="breadcrumb-item active">Last Rank</span>
          </nav>
        </div>
      </div>

      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="position-relative mb-3">
                <div className="overlay position-relative bg-light rresult">
                  <div className="mb-3 rresult">
                    <h3 className="mb-4">
                      Last Rank Details of Kerala Engineering Colleges 2023
                    </h3>
                    <form onSubmit={handleSubmitForm}>
                      <div className="row psubmit">
                        <div className="acrow col-sm-4">
                          <label>Admission: &nbsp;</label>
                        </div>
                        <div className="col-sm-8">
                          <select
                            name="eventSelect"
                            onChange={handleMenuOnChange}
                            style={{
                              width: "auto",
                              maxWidth: "100%",
                              height: "100%",
                            }}
                            className="form-select sstyle"
                          >
                            <option defaultChecked>Select Allotment</option>
                            {admissionEvents?.admissions?.map(
                              (admissionEvent: admission, index) => (
                                <option key={index} value={admissionEvent.id}>
                                  {admissionEvent.name}
                                </option>
                              )
                            )}{" "}
                          </select>
                        </div>
                      </div>

                      <div className="row psubmit">
                        <div className="acrow col-sm-4">
                          <label>Category: &nbsp;</label>
                        </div>
                        <div className="col-sm-8">
                          <select
                            name="categorySelect"
                            onChange={addCategory}
                            style={{ width: "auto" }}
                            className="form-select sstyle"
                          >
                            <option defaultChecked>Select Category</option>
                            {studentCat.map((e, index) => (
                              <option key={index} value={e.id}>
                                {e.code}
                              </option>
                            ))}{" "}
                          </select>
                        </div>
                      </div>

                      <div className="row psubmit">
                        <div className="acrow col-sm-4">
                          <label>Course: &nbsp;</label>
                        </div>
                        <div className="col-sm-8">
                          <select
                            name="courseSelect"
                            onChange={addCourse}
                            style={{ width: "100%" }}
                            className="form-select sstyle"
                          >
                            <option defaultChecked>Select Course</option>
                            {coursesList?.courses?.map((e, index) => (
                              <option key={index} value={e?.id}>
                                {e.name}
                              </option>
                            ))}{" "}
                          </select>
                        </div>
                      </div>

                      <div className="form-group mb-0 psubmit">
                        <input
                          type="submit"
                          value="Submit"
                          className="btn btn-primary font-weight-semi-bold py-2 px-3"
                        />
                      </div>
                    </form>
                    <div className="mtop">
                      {submitProcessing && (
                        <>
                          <button onClick={() => toPDF()}>Download PDF</button>
                          <div ref={targetRef} className="rresult">
                            <table className="table rtable">
                              <thead>
                                <tr>
                                  <th scope="col">#</th>
                                  <th scope="col">College (Code)</th>
                                  <th scope="col">Course (Code)</th>
                                  <th scope="col">Rank</th>
                                </tr>
                              </thead>{" "}
                              <tbody>
                                {collegeRankList.map((e, index) => (
                                  <>
                                    <tr>
                                      <th
                                        scope="row"
                                        key={index}
                                        className="col-sm-1"
                                        style={{ width: 2 }}
                                      >
                                        {index + 1}
                                      </th>
                                      <td className="col-sm-5">
                                        {e.college?.name?.replaceAll(",", ", ")}{" "}
                                        ({e.college?.code})
                                      </td>
                                      <td className="col-sm-5">
                                        {e.course?.name?.replaceAll(",", ", ")}{" "}
                                        ({e.course?.code})
                                      </td>
                                      <th className="col-sm-1">{e.rank}</th>
                                    </tr>
                                  </>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="container">
                            {disabled && (
                              <form onSubmit={handleSubmitForm}>
                                <button type="submit">Load more</button>
                              </form>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div id="result"></div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pt-3 pt-lg-0">
              <FollowUs />

              <NewsLetter />

              <div className="mb-3 pb-3">
                <script
                  type="text/javascript"
                  src="https://udbaa.com/bnr.php?section=General&pub=572899&format=300x250&ga=g"
                ></script>
                <noscript>
                  <a href="https://yllix.com/publishers/572899" target="_blank">
                    <img
                      src="//ylx-aff.advertica-cdn.com/pub/300x250.png"
                      className="ylixs"
                      alt="ylliX - Online Advertising Network"
                    />
                  </a>
                </noscript>
              </div>

              <div className="pb-3">
                <div className="bg-light py-2 px-4 mb-3">
                  <h3 className="m-0">Tranding</h3>
                </div>
                <div className="d-flex mb-3">
                  <img src="img/news-100x100-1.jpg" className="imgwhc" />
                  <div
                    className="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style={{ height: 100 }}
                  >
                    <div className="mb-1 fthirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h6 m-0" href="">
                      Lorem ipsum dolor sit amet consec adipis elit
                    </a>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <img src="img/news-100x100-2.jpg" className="imgwhc" />
                  <div
                    className="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style={{ height: 100 }}
                  >
                    <div className="mb-1 fthirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h6 m-0" href="">
                      Lorem ipsum dolor sit amet consec adipis elit
                    </a>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <img src="img/news-100x100-3.jpg" className="imgwhc" />
                  <div
                    className="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style={{ height: 100 }}
                  >
                    <div className="mb-1 fthirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h6 m-0" href="">
                      Lorem ipsum dolor sit amet consec adipis elit
                    </a>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <img src="img/news-100x100-4.jpg" className="imgwhc" />
                  <div
                    className="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style={{ height: 100 }}
                  >
                    <div className="mb-1 fthirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h6 m-0" href="">
                      Lorem ipsum dolor sit amet consec adipis elit
                    </a>
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <img src="img/news-100x100-5.jpg" className="imgwhc" />
                  <div
                    className="w-100 d-flex flex-column justify-content-center bg-light px-3"
                    style={{ height: 100 }}
                  >
                    <div className="mb-1 fthirteen">
                      <a href="">Technology</a>
                      <span className="px-1">/</span>
                      <span>January 01, 2045</span>
                    </div>
                    <a className="h6 m-0" href="">
                      Lorem ipsum dolor sit amet consec adipis elit
                    </a>
                  </div>
                </div>
              </div>

              <div className="pb-3">
                <div className="bg-light py-2 px-4 mb-3">
                  <h3 className="m-0">Tags</h3>
                </div>
                <div className="d-flex flex-wrap m-n1">
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Politics
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Business
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Corporate
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Sports
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Health
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Education
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Science
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Technology
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Foods
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Entertainment
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Travel
                  </a>
                  <a href="" className="btn btn-sm btn-outline-secondary m-1">
                    Lifestyle
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Admissions;
