import React, { FC, useEffect } from "react";
import styles from "./Logout.module.css";
import { removeToken } from "../../util/userDataSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function logoutAccount(event: React.MouseEvent<HTMLParagraphElement>): void {
    dispatch(removeToken());
    navigate("/login");
  }

  return (
    <>
      <p className="aright" onClick={logoutAccount}>
        Logout
      </p>
    </>
  );
};

export default Logout;
