import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../store/sessionManager";

const apiClient = axios.create();

apiClient.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export const api = {
  get: async (url: string, params: any) => {
    try {
      return await apiClient.get(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        throw e;
      } else {
        console.log("Unexpected error: ", e);
      }
    }
  },
  post: async (url: string, params: any) => {
    try {
      return await apiClient.post(url, params, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        throw e;
      } else {
        console.log("Unexpected error: ", e);
      }
    }
  },
  postWithFile: async (url: string, params: any) => {
    try {
      return await apiClient.post(url, params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        throw e;
      } else {
        console.log("Unexpected error: ", e);
      }
    }
  },
  delete: async (url: string, params: any) => {
    try {
      return await apiClient.delete(url, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (e: any) {
      if (axios.isAxiosError(e)) {
        throw e;
      } else {
        console.log("Unexpected error: ", e);
      }
    }
  },
};
