import React, { FC } from 'react';
import './AddCollege.css';

interface AddCollegeProps {}

const AddCollege: FC<AddCollegeProps> = () => (
  <div className="AddCollege">
    AddCollege Component
  </div>
);

export default AddCollege;
