import React, { FC, useEffect, useState } from "react";
import "./MainSlider.css";
import axios from "axios";
import { Country as ctry, educationCategory } from "../../interfaces";

interface MainSliderProps {}

const MainSlider: FC<MainSliderProps> = () => {
  const [countries, setCountries] = useState<ctry[]>();
  const [categories, setCategories] = useState<educationCategory[]>();
  const url = `https://apis.nuhman.com/`;

  useEffect(() => {
    console.log("reloading");
    axios
      .get(url + `apis/education/countries?bar=false`)
      .then((res) => {
        setCountries(res?.data);
      })
      .catch((err) => console.log(err));

    axios
      .get(url + `apis/education/education-categories`)
      .then((res) => {
        setCategories(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mmargin">
              <div className="bg-light rresults">
                <div className="mb-3 rresult">
                  <h3 className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    Education Resources by Countries
                  </h3>

                  <div className="position-relative overflow-hidden mb-3 cat">
                    {countries?.map((ctry: ctry, index) => (
                      <>
                        <div className="col-sm-4 intdisp">
                          <p>
                            <a
                              key={index}
                              href={
                                "/#/state?countryid=" +
                                ctry.id +
                                "&country=" +
                                ctry.name
                              }
                            >
                              {ctry.name}
                            </a>
                          </p>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="bg-light rresultser">
                <div className="mb-3 rresult">
                  <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    <h3 className="m-0">Categories</h3>
                    <a
                      className="text-secondary font-weight-medium text-decoration-none"
                      href="/#/categories"
                    >
                      View All
                    </a>
                  </div>
                  <div>
                    {categories?.slice(0, 5).map((ctry: ctry, index) => (
                      <div
                        className="position-relative overflow-hidden mb-3 cat"
                        key={index}
                      >
                        <h5>
                          <a href={"/#/categories?catid=" + ctry.id}>
                            {" "}
                            {ctry.name}
                          </a>{" "}
                        </h5>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainSlider;
