import React, { FC, useState } from "react";
import "./Contact.css";
import axios from "axios";
import { url } from "inspector";

interface ContactProps {}

const Contact: FC<ContactProps> = () => {
  const [message, setMessage] = useState<string>();
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [subject, setSubject] = useState<string>();
  const [responses, setResponses] = useState<string>();
  const [showForm, setShowForm] = useState(true);
  const url = `https://apis.nuhman.com/`;
  function submitContact(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    //throw new Error("Function not implemented.");

    axios
      .post(url + `apis/education/add-contact`, {
        name: name,
        subject: subject,
        email: email,
        message: message,
      })
      .then((res) => {
        setShowForm(false);

        setResponses(
          res?.status == 200
            ? "Form Submission successfull.. We will Contact you soon..."
            : "Error in Form Submission try email/phone"
        );
      })
      .catch((err) => console.log(err));

    event.currentTarget.reset();
  }
  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="bg-light py-2 px-4 mb-3">
            <h3 className="m-0">Contact Us For Any Queries</h3>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="bg-light mb-3" style={{ padding: 30 }}>
                <h6 className="font-weight-bold">Get in touch</h6>
                <p>
                  For any queries related to the informatiom, advertisement or
                  anything else you can message or email the contacts given
                  below. Most of the information is extracted from available
                  online resources.
                </p>
                <div className="d-flex align-items-center mb-3">
                  <i className="fa fa-2x fa-map-marker-alt text-primary mr-3"></i>
                  <div className="d-flex flex-column">
                    <h6 className="font-weight-bold">Our Office</h6>
                    <p className="m-0">Kitchener, ON</p>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-3">
                  <i className="fa fa-2x fa-envelope-open text-primary mr-3"></i>
                  <div className="d-flex flex-column">
                    <h6 className="font-weight-bold">Email Us</h6>
                    <p className="m-0">codewistechnologies@gmail.com</p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <i className="fas fa-2x fa-phone-alt text-primary mr-3"></i>
                  <div className="d-flex flex-column">
                    <h6 className="font-weight-bold">Call Us</h6>
                    <p className="m-0">+1 289 681 9799</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div
                className="contact-form bg-light mb-3"
                style={{ padding: 30 }}
              >
                <div id="success">{responses}</div>
                {showForm && (
                  <form
                    name="sentMessage"
                    id="contactForm"
                    onSubmit={submitContact}
                  >
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="control-group">
                          <input
                            type="text"
                            className="form-control p-4"
                            id="name"
                            name="name"
                            onChange={(event) => setName(event.target.value)}
                            placeholder="Your Name"
                            required
                            data-validation-required-message="Please enter your name"
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="control-group">
                          <input
                            type="email"
                            className="form-control p-4"
                            id="email"
                            name="email"
                            onChange={(event) => setEmail(event.target.value)}
                            placeholder="Your Email"
                            required
                            data-validation-required-message="Please enter your email"
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                    </div>
                    <div className="control-group">
                      <input
                        type="text"
                        className="form-control p-4"
                        id="subject"
                        name="subject"
                        placeholder="Subject"
                        onChange={(event) => setSubject(event.target.value)}
                        required
                        data-validation-required-message="Please enter a subject"
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                    <div className="control-group">
                      <textarea
                        className="form-control"
                        rows={4}
                        id="message"
                        name="message"
                        placeholder="Message"
                        onChange={(event) => setMessage(event.target.value)}
                        required
                        data-validation-required-message="Please enter your message"
                      ></textarea>
                      <p className="help-block text-danger"></p>
                    </div>
                    <div>
                      <button
                        className="btn btn-primary font-weight-semi-bold px-4"
                        style={{ height: 50 }}
                        type="submit"
                        id="sendMessageButton"
                      >
                        Send Message
                      </button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
