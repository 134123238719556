import React, { FC, useEffect, useState } from "react";
import "./StateCourses.css";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { admission, PaginatedCourseAdmissionStateDao } from "../../interfaces";

interface StateCoursesProps {}

const StateCourses: FC<StateCoursesProps> = () => {
  const [
    paginatedCourseAdmissionStateDao,
    setPaginatedCourseAdmissionStateDao,
  ] = useState<PaginatedCourseAdmissionStateDao>();
  const url = `https://apis.nuhman.com/`;

  const [searchParams] = useSearchParams();

  const stid = searchParams.get("stateid");
  const cid = searchParams.get("courseid");
  const sname = searchParams.get("state");
  const cname = searchParams.get("course");

  useEffect(() => {
    console.log("reloading");
    axios
      .get(
        url + "apis/education/course-state?stateid=" + stid + "&courseId=" + cid
      )
      .then((res) => {
        setPaginatedCourseAdmissionStateDao(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="overlay position-relative bg-light rresult">
                <div className="mb-3 rresult">
                  <h3 className="mb-4">
                    {cname} admissions in {sname}
                  </h3>
                  {paginatedCourseAdmissionStateDao?.admissions?.map(
                    (ctry: admission, index) => (
                      <>
                        <div key={index}>
                          <a
                            href={
                              "/#/admissions?adid=" + ctry.id + "&csid=" + cid
                            }
                          >
                            {ctry.name}
                          </a>
                        </div>
                      </>
                    )
                  )}
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StateCourses;
