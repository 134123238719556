import React, { FC, useEffect, useState } from "react";
import "./University.css";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { University as uni, educationCategoryDAO } from "../../interfaces";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import { Helmet } from "react-helmet";

interface UniversityProps {}

const University: FC<UniversityProps> = () => {
  const [university, setUniversity] = useState<uni>();

  const url = `https://apis.nuhman.com/`;

  const [searchParams] = useSearchParams();
  const cid = searchParams.get("universityid");

  useEffect(() => {
    console.log("reloading");
    axios
      .get(url + "apis/education/university/" + cid)
      .then((res) => {
        setUniversity(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Helmet>
        <title>{"College Searchers.com - " + university?.name}</title>
        <meta
          property="og:title"
          content={"College Searchers.com - " + university?.name}
        />
        <meta
          property="og:description"
          content={
            "College Searchers.com - " +
            university?.name +
            ". Courses, Articles, Facilities and news"
          }
        />
        <meta
          property="description"
          content={
            "College Searchers.com - " +
            university?.name +
            ". Courses, Articles, Facilities and news"
          }
        />
      </Helmet>

      <BreadCrumb
        links={[
          { link: "/#/home", title: "Home" },
          { link: "/#/country", title: "Country" },
          {
            link:
              "/#/state?countryid=" +
              university?.country?.id +
              "&country=" +
              university?.country?.name,
            title: "State",
          },
        ]}
        title={" " + university?.name}
      />
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mmargin">
              <div className="bg-light rresults">
                <div className="mb-3 rresult">
                  <h2 className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    {university?.name}{" "}
                  </h2>
                  <p>
                    Disclaimer: Information on this website may not be accurate,
                    please verify with university official website
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="bg-light rresultser">
                <div className="mb-3 rresult">
                  <div className="">
                    <h5> {university?.address1}</h5>
                    <h5> {university?.city}</h5>
                    <h5> {university?.zip}</h5>
                    <h5> {university?.state?.name}</h5>
                    <h5> {university?.country?.name}</h5>
                  </div>
                  <div className="">
                    <h5>
                      {" "}
                      {university?.website && (
                        <>
                          Website:{" "}
                          <a href={university?.website}>
                            {university?.website}
                          </a>
                        </>
                      )}
                    </h5>
                  </div>
                  <div>
                    <script
                      type="text/javascript"
                      src="https://udbaa.com/bnr.php?section=General&pub=572899&format=300x250&ga=g"
                    ></script>
                    <noscript>
                      <a
                        href="https://yllix.com/publishers/572899"
                        target="_blank"
                      >
                        <img
                          src="//ylx-aff.advertica-cdn.com/pub/300x250.png"
                          className="ylixs"
                          alt="ylliX - Online Advertising Network"
                        />
                      </a>
                    </noscript>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default University;
