import React, { FC, useEffect, useState } from "react";
import "./CategoriesList.css";
import axios from "axios";
import {
  Article,
  Articles,
  Country as ctry,
  educationCategory,
} from "../../interfaces";

interface CategoriesListProps {
  catId: number;
}

const CategoriesList: FC<CategoriesListProps> = ({ catId }) => {
  const [countries, setCountries] = useState<ctry[]>();
  const [categories, setCategories] = useState<educationCategory[]>();
  const [articles, setArticles] = useState<Articles>({});
  const [articleCategory, setArticleCategory] = useState<educationCategory>();
  const url = `https://apis.nuhman.com/`;

  useEffect(() => {
    console.log("reloading");
    axios
      .get(url + `apis/education/countries?bar=true`)
      .then((res) => {
        setCountries(res?.data);
      })
      .catch((err) => console.log(err));
    if (catId == 0) {
      setArticles({});
      axios
        .get(url + `apis/education/education-categories`)
        .then((res) => {
          setCategories(res?.data);
        })
        .catch((err) => console.log(err));
    } else {
      setCategories([]);
      axios
        .get(url + `apis/education//education-categories/` + catId)
        .then((res) => {
          setArticleCategory(res?.data);
        })
        .catch((err) => console.log(err));

      axios
        .get(
          url +
            "apis/education/articles?pageSize=20&educationCategoryId=" +
            catId
        )
        .then((res) => {
          setArticles(res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [catId]);

  return (
    <>
      <div className="container-fluid py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mmargin">
              <div className="bg-light rresults">
                <div className="mb-3 rresult">
                  <h2 className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    {(categories?.length || 0 > 0) &&
                      "Education Resources by Categories"}
                    {(articles?.articles?.length || 0 > 0) &&
                      articleCategory?.name + " Articles"}
                  </h2>

                  <div className="position-relative overflow-hidden mb-3 cat">
                    {categories?.map((ctry: educationCategory, index) => (
                      <>
                        <div className="col-sm-6 intdisp">
                          <p>
                            <a
                              key={index}
                              href={"/#/categories?catid=" + ctry.id}
                            >
                              {ctry.name}
                            </a>
                          </p>
                        </div>
                      </>
                    ))}

                    {(articles?.articles?.length || 0 > 0) &&
                      articles?.articles?.map((articleRes: Article, index) => (
                        <>
                          <div className="col-sm-6 intdisp">
                            <div className="d-flex mb-3">
                              <img
                                src={
                                  "https://collegesearchers.com/images/" +
                                    articleRes.image100x100 || "test"
                                }
                                className="imgwhcover"
                              />
                              <div className="w-100 d-flex flex-column justify-content-center bg-light px-3 hhundred">
                                <div className="mb-1 headerthirteen">
                                  <span>
                                    {new Date(
                                      articleRes?.createdDateTime || ""
                                    ).toLocaleDateString("en-US", {
                                      weekday: "long",
                                      year: "numeric",
                                      month: "long",
                                      day: "numeric",
                                    })}
                                  </span>
                                </div>
                                <a
                                  className="h6 m-0"
                                  href={
                                    "/#/article?articleid=" + articleRes?.id
                                  }
                                >
                                  {articleRes?.title}
                                </a>
                                <p></p>
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="bg-light rresultser">
                <div className="mb-3 rresult">
                  <div className="d-flex align-items-center justify-content-between bg-light py-2 px-4 mb-3">
                    <h4 className="m-0">Resource by Countries</h4>
                    <a
                      className="text-secondary font-weight-medium text-decoration-none"
                      href="/#/country"
                    >
                      View All
                    </a>
                  </div>
                  <div>
                    {countries?.slice(0, 5).map((ctry: ctry, index) => (
                      <div
                        className="position-relative overflow-hidden mb-3 cat"
                        key={index}
                      >
                        <h5>
                          <a href={"/#/categories?catid=" + ctry.id}>
                            {" "}
                            {ctry.name}
                          </a>{" "}
                        </h5>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriesList;
